<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 13:34:41
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:54:14
 * @FilePath: \crm-education\src\views\student\my_class\index.vue
-->
<template>
  <div class="my-class">
    <!-- 后续开发 -->
    <!-- <div class="mb-10">
      <a-card class="cus-card no-border" :bordered="false">
        <template slot="title">
          <div class="section-header dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">我的老师</h2>
            <p></p>
          </div>
        </template>
        <div class="py-10">
          <a-row :gutter="20">
            <a-col :md="{ span: 8 }" :ms="{ span: 12 }" :xs="{ span: 24 }" v-for="info in myTeacherInfo" :key="info.id">
              <my-class-teacher-info :info="info"></my-class-teacher-info>
            </a-col>
          </a-row>
        </div>
      </a-card>
    </div>
    <div class="mb-10">
      <a-card class="cus-card no-border" :bordered="false">
        <template slot="title">
          <div class="section-header dec d-flex align-center justify-between flex-wrap">
            <h2 class="base-title fs-18">我的同学</h2>
            <p></p>
          </div>
        </template>
        <div class="py-10">
          <a-row :gutter="20">
            <a-col
              :md="{ span: 6 }"
              :ms="{ span: 12 }"
              :xs="{ span: 24 }"
              v-for="info in mySchoolMateInfo"
              :key="info.id"
            >
              <my-class-student-info :info="info"></my-class-student-info>
            </a-col>
          </a-row>
        </div>
        <div class="d-flex justify-end align-center">
          <a-button type="primary" class="cus-button ml-10">上一页</a-button>
          <a-button type="primary" class="cus-button ml-10">下一页</a-button>
        </div>
      </a-card>
    </div> -->
    <a-card class="cus-card no-border" :bordered="false">
      <a-empty>
        <span slot="description"> 该功能正在建设中</span>
      </a-empty>
    </a-card>
  </div>
</template>

<script>
// import helper from '@/utils/helper'
// import MyClassTeacherInfo from './c/MyClassTeacherInfo'
// import MyClassStudentInfo from './c/MyClassStudentInfo'
import api from '@/api'
export default {
  name: 'my_class',
  components: {
    // MyClassTeacherInfo,
    // MyClassStudentInfo
  },
  data() {
    return {
      // studId: helper.getUserInfo()('userId'),
      myTeacherInfo: [],
      mySchoolMateInfo: []
    }
  },
  mounted() {
    // this.init()
  },
  methods: {
    async init() {
      this.initMyTeacher()
      this.initMySchoolMate()
    },
    async initMyTeacher() {
      let res = await api.student.getMyTeacher()
      if (res.code === 200) {
        this.myTeacherInfo = res.data
      }
    },
    async initMySchoolMate() {
      let res = await api.student.getMySchoolMate()
      if (res.code === 200) {
        this.mySchoolMateInfo = res.data
      }
    }
  }
}
</script>
